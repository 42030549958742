<template>
  <v-card>
    <div class="tw-px-2 md:tw-px-6">
      <card-title icon="mdi-magnify" :is-image="false"
        >Choose the next step in your process</card-title
      >
    </div>
    <div
      class="
        tw-px-8
        md:tw-px-12
        tw-grid tw-grid-cols-1
        md:tw-grid-cols-3
        tw-gap-4
        md:tw-gap-8
        tw-mt-3
      "
    >
      <template v-for="(assessmentPanel, i) in assessmentPanels">
        <div v-if="viewPermissions(assessmentPanel.roles)" :key="i">
          <v-card
            min-height="350"
            class="mt-5 mb-5 text-center tw-w-full"
            elevation="3"
          >
            <br />
            <v-img
              :src="assessmentPanel.src"
              class="mx-auto my-5"
              width="60"
              height="auto"
            />
            <h4>{{ assessmentPanel.heading }}</h4>
            <v-card-text
              ><p>
                {{ assessmentPanel.text }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="actionpanel mx-auto mb-5 pa-5"
                large
                color="primary"
                :to="assessmentPanel.link"
              >
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import { viewPermissions } from '@/utils/roles'
export default {
  components: { CardTitle },
  data: () => ({
    assessmentPanels: [
      {
        src: require('@/assets/img/assesment-center/booking.png'),
        heading: 'Vehicle Booking',
        text: 'For booking and verification service, checking assessment qualifications',
        link: { name: 'BookVehicle' },
        roles: ['internalAssessor', 'technician'],
      },
      {
        src: require('@/assets/img/assesment-center/vehicle-inspection.png'),
        heading: 'Internal Assessor',
        text: 'For vehicle inspection, bid analysis and supplementary approval',
        link: '/vehicle-inspection',
        roles: ['internalAssessor', 'technician'],
      },
      {
        src: require('@/assets/img/assesment-center/major-accident.png'),
        heading: 'External Assessor',
        text: 'Motor assessment, supplementary and re-inspection',
        link: { name: 'MajorAccident' },
        roles: ['internalAssessor', 'technician', 'externalAssessor'],
      },
      {
        src: require('@/assets/img/assesment-center/bid-submited.png'),
        heading: 'Bids Received',
        text: 'All bids received',
        link: { name: 'BidsSubmitted' },
        roles: ['internalAssessor', 'technician'],
      },
      {
        src: require('@/assets/img/assesment-center/qualified-bids.png'),
        heading: 'Qualified Bids',
        text: 'Bids Qualified by Assessors',
        link: { name: 'QualifiedBids' },
        roles: ['internalAssessor', 'technician', 'claimOfficer'],
      },
      {
        src: require('@/assets/img/assesment-center/major-accident.png'),
        heading: 'Vehicle Checkout',
        text: 'A checklist to check out vehicles that have been analysed with key status',
        link: { name: 'VehicleCheckout' },
        roles: ['admin', 'internalAssessor', 'technician'],
      },
      {
        src: require('@/assets/img/assesment-center/all-bids.png'),
        heading: 'All Bid Reports',
        text: 'All the bids that have been submitted with an assigned garage',
        link: { name: 'BidReports' },
        roles: ['internalAssessor', 'technician', 'claimOfficer'],
      },
      {
        src: require('@/assets/img/assesment-center/write-off.png'),
        heading: 'Write Off Reports',
        text: 'All Written Off Reports',
        link: { name: 'WriteOffReports' },
        roles: ['internalAssessor', 'technician', 'claimOfficer'],
      },
      {
        src: require('@/assets/img/assesment-center/cash-in-lieu.png'),
        heading: 'Cash in Lieu Reports',
        text: 'All Cash in Lieu Reports',
        link: { name: 'CashInLieuReports' },
        roles: ['internalAssessor', 'technician', 'claimOfficer'],
      },
      {
        src: require('@/assets/img/assesment-center/below-excess.png'),
        heading: 'Below Excess Reports',
        text: 'All Below Excess Reports',
        link: { name: 'BelowExcessReports' },
        roles: ['internalAssessor', 'technician', 'claimOfficer'],
      },
      {
        src: require('@/assets/img/assesment-center/all-bids.png'),
        heading: 'Under Investigation Reports',
        text: 'For checking bids that are still under investigation',
        link: { name: 'UnderInvestigationReports' },
        roles: ['admin', 'internalAssessor', 'sectionHead'],
      },
      {
        src: require('@/assets/img/assesment-center/all-bids.png'),
        heading: 'Undocumented Reports',
        text: 'For checking bids that are undocumented',
        link: { name: 'UndocumentedReports' },
        roles: ['admin', 'internalAssessor', 'sectionHead'],
      },
      {
        src: require('@/assets/img/assesment-center/all-bids.png'),
        heading: 'Declined Reports',
        text: 'For checking bids that have been declined',
        link: { name: 'DeclinedReports' },
        roles: [
          'admin',
          'claimOfficer',
          'internalAssessor',
          'sectionHead',
          'technician',
        ],
      },
    ],
  }),
  methods: {
    viewPermissions,
  },
}
</script>
